<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              部门：
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div>
            <div class="search_center">
              姓名：
              <el-input
                v-model="parameter.userName"
                class="ipt_width"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              单据编号：
              <el-input
                v-model="parameter.expenseNumber"
                class="ipt_width expense__width"
                placeholder="请输入单据单编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              项目名称：
              <el-input
                placeholder="请输入项目名称"
                v-model="parameter.projectContent"
                class="ipt_width project_width"
                clearable
              >
              </el-input>
            </div>

            <div class="search_center">
              申请日期：
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">预支流程</el-button>
          <el-button type="success" style="padding: 0"
            ><a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/经费预支单据操作手册.pdf"
              >操作文档</a
            ></el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType" class="fullScreenMainHeader">
            <el-tab-pane name="NOT_PAYED" label="待发放"></el-tab-pane>
            <el-tab-pane name="ALREADY_PAYED" label="已发放"></el-tab-pane>
          </el-tabs>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              prop="expenseNumber"
              label="单据编号"
              align="center"
              min-width="170"
            ></el-table-column>
            <el-table-column
              prop="createName"
              min-width="110"
              sortable
              label="申请人"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="deptName"
              min-width="110"
              sortable
              label="部门"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="projectContent"
              :show-overflow-tooltip="false"
              min-width="320"
              sortable
              label="项目名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="priority"
              :show-overflow-tooltip="false"
              min-width="120"
              label="是否优先发放"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.priority == 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="companyType"
              :show-overflow-tooltip="false"
              min-width="130"
              sortable
              label="费用归属单位"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyTypeList) }}
              </template>
            </el-table-column>
            <el-table-column prop="cost" sortable min-width="110" label="预支金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.cost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="taskDate"
              sortable
              min-width="110"
              label="申请日期"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="predictCounteractDate"
              min-width="170"
              sortable
              label="预计冲销/归还时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.predictCounteractDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="statusName"
              sortable
              min-width="150"
              label="流程进度"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="70">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  v-if="parameter.approvalType == 'NOT_PAYED'"
                  class="text_Grant_Bgc"
                  @click.stop="handleDetails(scope.row, 'NOT_PAYED')"
                >
                  发放
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  v-else
                  class="text_Details_Bgc"
                  @click.stop="handleDetails(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit v-if="editShow" :isShow.sync="editShow" :options="options"></Edit>
    <el-dialog title="经费预支流程图" :visible.sync="dialogVisible" width="75%">
      <img src="@/assets/经费预支流程.png" alt="经费预支流程" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../dlg/Edit.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        userName: null,
        expenseNumber: null,
        projectContent: null,
        deptId: null,
        pageNow: 1,
        pageSize: 50,
        approvalType: 'NOT_PAYED',
        total: 0, // 表格 -- 数据 总数量
      },
      pageSize: 0,
      tableData: [], // 表格数据
      deptList: [], // 部门 列表
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      companyTypeList: [],
      createdDate: [],
      dialogVisible: false,
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      options: {},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    'parameter.approvalType': {
      // immediate:true,//初始化立即执行
      // deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
    this.getDeptList()
    this.getTableData() /** 加载 数据 **/
  },

  methods: {
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 加载数据 **/
    getTableData() {
      this.loading = true
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }
      this.$api.funds
        .searchTask(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = []
          this.parameter.total = 0
          this.tableData = res.data?.records
          this.parameter.total = res?.data?.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.userName = null
      this.parameter.expenseNumber = null
      this.parameter.projectContent = null
      this.parameter.deptId = null
      this.createdDate = []
      this.parameter.pageNow = 1
      this.getTableData() /** 加载 数据 **/
    },

    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item, key) {
      this.options = item
      this.options.disabled = true
      this.options.isExport = true
      this.options.isPrint = true
      this.options.isGrant = false
      this.options.title = '支付预支单详情'
      if (key === 'NOT_PAYED') {
        this.options.isGrant = true
      }
      this.editShow = true
    },
    // 分页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },
    // 条数
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.getTableData()
      this.editShow = false
    },
    getType() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.mainList .mainList_content .mainList_content_sub .el-table {
  height: calc(100% - 93px);
}

.status {
  margin-bottom: 15px;
}
</style>
